// Bordered & Pulled
// -------------------------

.#{$la-css-prefix}-border {
    border: solid 0.08em #eee;
    border-radius: .1em;
    padding: .2em .25em .15em;
}

.#{$la-css-prefix}-pull-left { float: left; }
.#{$la-css-prefix}-pull-right { float: right; }

.#{$la-css-prefix} {
    &.#{$la-css-prefix}-pull-left { margin-right: .3em; }
    &.#{$la-css-prefix}-pull-right { margin-left: .3em; }
}

.#{$la-css-prefix} {
    &.pull-left { margin-right: .3em; }
    &.pull-right { margin-left: .3em; }
}
