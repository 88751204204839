.datableBody{
  .rdt_Table{
    .rdt_TableHead{
      .rdt_TableHeadRow{
          min-height: 45px;
        .rdt_TableCol{
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .badge {
      font-size: 13px;
    }
  }
  header{
    padding: 0px !important;
  }
}

@media (max-width: 700px) {
  .w-25 {
    width: 50% !important;
  }
}

@media (min-width: 992px) {
  .mobile-left {
    text-align: right;
  }
}
