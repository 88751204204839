.#{$la-css-prefix}-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -.0667em;
}

.#{$la-css-prefix}-xs { font-size: 0.75em; }
.#{$la-css-prefix}-2x { font-size: 1em; }
.#{$la-css-prefix}-2x { font-size: 2em; }
.#{$la-css-prefix}-3x { font-size: 3em; }
.#{$la-css-prefix}-4x { font-size: 4em; }
.#{$la-css-prefix}-5x { font-size: 5em; }
.#{$la-css-prefix}-6x { font-size: 6em; }
.#{$la-css-prefix}-7x { font-size: 7em; }
.#{$la-css-prefix}-8x { font-size: 8em; }
.#{$la-css-prefix}-9x { font-size: 9em; }
.#{$la-css-prefix}-10x { font-size: 10em; }

.#{$la-css-prefix}-fw {
  text-align: center;
  width: 1.25em;
}
