// Admin Layout (ALUI)
// @author: WrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: ALUI
// Copyright 2021 WrapTheme

// card 
.card{
    border-color: var(--border-color);
    background-color: var(--card-color);
    &.card-callout{
        border-left-width: .4rem;
        border-left-color: var(--primary-color);
    }
    .card-title{
        color: var(--color-900);
        font-weight: 600;
    }
}

// card ribbon 
.ribbon{
    position: relative;
    overflow: hidden;

    .option-1{
        height: 2rem;
        top: 1.2rem;
        left: 0;
        width: 5px;
    }
    .option-2{
        height: 5px;
        left: 1.2rem;
        top: 0;
        width: 2rem;
    }
    .option-3{
        transform: rotate(315deg);
        left: -50px;
        top: 8px;
        padding: 5px 15px;
        text-align: center;
        width: 150px;
    }
    .option-4{
        transform: rotate(315deg);
        right: -50px;
        bottom: 8px;
        padding: 5px 15px;
        text-align: center;
        width: 150px;
    }
    .option-5{
        transform: rotate(315deg);
        left: -27px;
        top: -5px;
        padding: 5px;
        text-align: center;
        width: 78px;
    }
    .option-6{
        transform: rotate(46deg);
        right: -33px;
        top: -5px;
        padding: 8px 0 1px 6px;
        text-align: center;
        width: 90px;
    }
    .option-7{
        background: var(--primary-color);
        left: 0;
        top: 15px;
        height: 30px;
        padding: 5px 0 5px 10px;

        &::before{
            position: absolute;
            top: 0;
            left: 100%;
            display: block;
            content: '';
            border: 15px solid var(--primary-color);
            border-right: 8px solid transparent;
            border-right-color: transparent;
        }
    }
    .option-8{
        background: var(--primary-color);
        right: 0;
        top: 15px;
        height: 30px;
        padding: 5px 10px 5px 0;

        &::before{
            position: absolute;
            top: 0;
            right: 100%;
            display: block;
            content: '';
            border: 15px solid var(--primary-color);
            border-left: 8px solid transparent;
            border-left-color: transparent;
        }
    }
    .option-9{
        background: var(--primary-color);
        text-align: center;
        right: 20px;
        top: 0;
        width: 30px;
        padding: 5px 5px 0 5px;

        &::before{
            position: absolute;
            top: 22px;
            right: 0;
            display: block;
            content: '';
            border: 15px solid var(--primary-color);
            border-bottom: 5px solid transparent;
            border-bottom-color: transparent;
        }
    }
    .option-10{
        background: var(--primary-gradient);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 50% calc(100% - 6px), 0 calc(100% - 12px));
        transition: clip-path 1s, padding 1s, background 1s;
        text-align: center;
        right: 20px;
        top: 0;
        width: 30px;
        height: 40px;
        padding: 5px;
    }
    .option-11{
        background: var(--chart-color1);
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 8px), 50% 100%, 0 calc(100% - 8px));
        transition: clip-path 1s, padding 1s, background 1s;
        text-align: center;
        right: 20px;
        top: 0;
        width: 30px;
        height: 40px;
        padding: 5px;
    }
    .option-12{
        background: var(--chart-color3);
        clip-path: polygon(0 0, 100% 0, 100% calc(100% - 20px), 40% 100%, 0 calc(100% - 12px));
        transition: clip-path 1s, padding 1s, background 1s;
        text-align: center;
        right: 20px;
        top: 0;
        width: 30px;
        height: 40px;
        padding: 5px;
    }
    .option-13{
        height: 100%;
        width: 100%;
        position: relative;

        + .card-body{
            z-index: 99;
            position: relative;
        }

        &::after{
            background: repeating-linear-gradient(45deg, var(--card-color) , var(--card-color) 10px, #3C7AB8 0, #3C7AB8 20px, var(--card-color) 0, var(--card-color) 30px, #CB2C4B 0, #CB2C4B 40px);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            content: "";
            z-index: 0;
        }            
        &::before{
            background-color: var(--card-color);
            position: absolute;
            top: 5px;
            left: 5px;
            height: calc(100% - 10px);
            width: calc(100% - 10px);
            content: "";
            z-index: 1;
            border-radius: 10px;
        }
    }
}

// Experience widgets
.resume-box {
    margin: 0;
    padding: 30px 20px;
    list-style: none;

    li {
        position: relative;
        padding: 0 20px 0 60px;
        margin: 0 0 30px;

        &:last-child {
            margin-bottom: 0;
        }
        &:after {
            content: "";
            position: absolute;
            border-left: 1px dashed var(--primary-color);
            top: 0px;
            left: 20px;
            bottom: 0;
        }
    }
    .icon {
        color: var(--primary-color);
        background: var(--card-color);
        position: absolute;
        border: 1px dashed;
        width: 40px;
        height: 40px;
        left: 0;
        right: 0;
        line-height: 40px;
        z-index: 1;
        border-radius: 50%;
    }
    .time {
        background: var(--primary-color);
        color: $white;
        display: inline-block;
        font-size: 10px;
        padding: 2px 10px;
        margin-bottom: 12px;
        border-radius: 20px;
    }
    &.agent-profile {
        li {
            margin-bottom: 0;
            &::after {
                display: none;
            }
        }
    }
}

// food-order
.food-order{
    .details{
        @include overflow(scroll);
        min-width: 360px;

        @media only screen and (max-width: $break-small - 1px) {
            display: none;
            min-width: 300px;
            &.open{
                display: block;
                position: absolute;
                right: 0;
                top: 0;
                z-index: 9;
                height: 100vh;
            }
        }
    }
}

// Product Card
.product-card {
    position: relative;
    .product-img {
        transition: ease-in-out 0.2s all;
        position: relative;
        top: 0;
        .btn-hover {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: -1;
            transition: ease-in-out 0.2s all;
            width: 100%;
        }
        &:hover {
            transition: ease-in-out 0.2s all;
            top: -15px;
            .btn-hover {
                position: absolute;
                bottom: -17px;
                z-index: 1;
                transition: ease-in-out 0.2s all;
            }
        }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.chat-widgets{
    .form-control:focus{
        box-shadow: none;
    }
}

.rating-div{
    ul{
        padding: 0;    
    }
}