// Admin Layout (ALUI)
// @author: WrapTheme ( https://themeforest.net/user/wrraptheme )
// @design by: ThemeMakker Infotech LLP.
// @event-namespace: ALUI
// Copyright 2021 WrapTheme

.table {
    &.myDataTable{
		width: 100% !important;
		.dtr-details{
			width: 100%;
			li{
				border-color: var(--border-color) !important;
			}
		}
		tr{
            td, th{
                
                &:first-child{
                    outline: none;
                }
            }
        }
	}
	&.table-lg{
		&.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>{
			td:first-child,
			th:first-child{
				&::before{
					top: 24px;
				}
			}
		}
	}

	&.dataTable{
		width: 100% !important;
		white-space: nowrap;
		.form-check{
			padding-top: 2px;
		}
		.dtr-details{
			list-style: none;
			padding: 0;
			margin-bottom: 0;
			li{
				padding: 6px 0;
				border-bottom: 1px solid var(--border-color);
				&:last-child{
					border-bottom: 0;
				}
				.dtr-title{
					position: relative;
					font-weight: 600;
					padding-right: 20px;
					&::after{
						content: ':';
						position: absolute;
						right: 10px;
					}
				}
			}
		}
		&.dtr-inline{
			&.collapsed{
				>tbody{
					>tr.odd,
					>tr.even{
						>td, >th{
							position: relative;
							&:first-child{
								padding-left: 26px;
								&::before{
									@include transition(ease .3s);
									background-color: var(--secondary-color);
									color: $dark;
									font-family: 'FontAwesome';
									content: '\f105';
									text-align: center;
									position: absolute;
									left: 0;
									top: 50%;
									width: 16px;
									height: 16px;
									line-height: 16px;
									transform: translateY(-50%);
								}
							}
						}
					}
					>tr.parent{
						>td:first-child:before,
						>th:first-child:before{
							content: '\f107';
							background-color: var(--primary-color);
							color: $white;
						}
					}
				}
			}
		}
		&.normal {
			th,
			td {
				white-space: normal;
			}
		}
	}
}